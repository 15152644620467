export const SET_USER = "SET_USER";
export const SET_SELECTED = "SET_SELECTED";
export const UPDATE_SELECTED = "UPDATE_SELECTED";
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const ADD_ITEMS = "ADD_ITEMS";
export const SET_ITEM_STAR = "SET_ITEM_STAR";
export const UPDATE_STAR_LIST = "UPDATE_STAR_LIST";
export const ADD_MORE_STAR_LIST = "ADD_MORE_STAR_LIST";
export const SET_ATTENTION = "SET_ATTENTION";
export const SET_STAR_LIST_STAR = "SET_STAR_LIST_STAR";
export const SET_USER_INFO = "SET_USER_INFO";
export const REPLACE_USER_INFO = "REPLACE_USER_INFO";
export const ADD_USER_COLLECTION_CATS = "ADD_USER_COLLECTION_CATS";
export const ADD_CART_LIST = "ADD_CART_LIST";
export const ADD_ORDER_LIST = "ADD_ORDER_LIST";
export const PRE_COMMIT_ORDER = "PRE_COMMIT_ORDER";
export const ADD_ORDER_BACK = "ADD_ORDER_BACK";
export const SET_TIP = "SET_TIP";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_SKUS = "SET_SKUS";
export const SET_CATS = "SET_CATS";
export const GET_DISPLAY_INFOS = 'GET_DISPLAY_INFOS';
export const GET_DISPLAY_INFO = 'GET_DISPLAY_INFO';
export const CLEAR_DISPLAY_INFOS = 'CLEAR_DISPLAY_INFOS';
export const SAVE_DISPLAY_SCROLLTOP = 'SAVE_DISPLAY_SCROLLTOP'
export const SET_BANNERS = 'SET_BANNERS';
export const SET_FEATURES = 'SET_FEATURES';
export const CREATE_ORDER = 'CREATE_ORDER'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const UPDATE_ORDER_INFOS = 'UPDATE_ORDER_INFOS'
export const CHANGE_QRCODE_PAGE = 'CHANGE_QRCODE_PAGE'
export const CHANGE_QRCODE_QUERY = 'CHANGE_QRCODE_QUERY'
export const CREATE_QRCODE = 'CREATE_QRCODE'
export const CHANGE_QRCODE_PATH = 'CHANGE_QRCODE_PATH'
export const SET_THIRD_AUTH_LINK = 'SET_THIRD_AUTH_LINK'

