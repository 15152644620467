import { handleActions } from 'redux-actions';
import { SelectedState } from '../constants/models';
import { SET_SELECTED, UPDATE_SELECTED } from '../constants/actionTypes';

const SelectedReducers = handleActions({
    UPDATE_SELECTED: (state, { payload }) => {

        if (state.get('isDiamond')) {
            if (payload.isBigDiamond) {
                payload.isBigDiamond = true;
            } else if ((payload.length && payload.width) || payload.diameter || (payload.minWeight && payload.maxWeight)) {
                payload.isBigDiamond = false;
                payload.cuttingId = null;
                payload.cuttingName = null;
                payload.polishingId = null;
                payload.polishingName = null;
                payload.symmetryId = null;
                payload.symmetryName = null;
                payload.fluorescentId = null;
                payload.fluorescentName = null;
            } else {
                delete payload.isBigDiamond;
            }
        } else {
            payload.isBigDiamond = false;
        }

        // 钻石类目特有字段
        if (payload.subCatId && (payload.subCatId == 26 || payload.subCatId == 27)) {
            payload.isDiamond = true;
        } else if (payload.subCatId && !payload.subCatName.includes('钻')) {
            payload.isDiamond = false;
            payload.isBigDiamond = false;
        }


        // size/weight互斥关系
        if (payload.diameter || payload.length) {
            payload.minWeight = null;
            payload.maxWeight = null;
            payload.weightName = null;
        }
        if (payload.length) {
            payload.diameter = null;
        } else if (payload.diameter) {
            payload.length = null;
            payload.width = null;
        }
        if (payload.minWeight) {
            payload.diameter = null;
            payload.length = null;
            payload.width = null;
            payload.sizeName = null;
        }

        return state.merge(payload);
    }
}, SelectedState);

export default SelectedReducers;