import Immutable from 'immutable';
import {
  handleActions
} from 'redux-actions';
import {
  QRCodeState
} from '../constants/models';
import {
  CHANGE_QRCODE_PAGE,
  CHANGE_QRCODE_PATH,
  CHANGE_QRCODE_QUERY,
  CREATE_QRCODE,
} from '../constants/actionTypes';

const QRCodeReducers = handleActions({
  [CHANGE_QRCODE_PAGE]: (state, { payload }) => {
    const pages = state.get('pages').toJS()

    return state.merge({
      page: payload,
      query: Immutable.fromJS({}),
      path: pages.filter(v => v.key === payload[0])[0].path,
      qrcode: null,
    })
  },
  [CHANGE_QRCODE_PATH]: (state, { payload }) => {
    return state.merge({
      path: payload,
      qrcode: null,
    })
  },
  [CHANGE_QRCODE_QUERY]: (state, { payload }) => {
    const query = state.get('query');

    return state.merge({
      query: query.merge(payload),
      qrcode: null,
    })
  },
  [CREATE_QRCODE]: (state, { payload }) => {
    return state.merge({
      qrcode: payload,
    });
  },
}, QRCodeState);

export default QRCodeReducers;