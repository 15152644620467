import {handleActions} from 'redux-actions';
import {OrderState} from '../constants/models';
import {CREATE_ORDER, UPDATE_ORDER_INFOS} from '../constants/actionTypes';

const OrderReducers = handleActions({
    [CREATE_ORDER]: (state, { payload }) => {
      return state.merge({
        orderInfos: payload
      })
    },
    [UPDATE_ORDER_INFOS]: (state, { payload }) => {
      const orderInfos = state.get('orderInfos')
      const infos = orderInfos || {}

      return state.merge({
        orderInfos: {
          ...infos,
          ...payload
        }
      })
    }
}, OrderState);

export default OrderReducers;