import {handleActions} from 'redux-actions';
import {CatState} from '../constants/models';
import {SET_CATS} from '../constants/actionTypes';

const CatsReducers = handleActions({
    SET_CATS: (state, {payload}) => {
        return state.merge(payload);
    },
}, CatState);

export default CatsReducers;