import Immutable from 'immutable';


export const UserState = Immutable.fromJS({
});
export const SelectedState = Immutable.fromJS({
    subCat: null,
    shape: null,
    minWeight: null,
    maxWeight: null,
    length: null,
    width: null,
    diameter: null,
    color: null,
    cutting: null,
    clarity: null,
    polishing: null,
    symmetry: null,
    fluorescent: null,
    haveWeight: false, // 是否可以选择重量，重量跟尺寸二者选其一
    isDiamond: false, // 是否是钻石筛选项
    isBigDiamond: false, // 是否是钻石主石
    showOptionalFields: false // 是否展示可选项
});
export const OptionsState = Immutable.fromJS({});
export const CatState = Immutable.fromJS({});
export const ItemsState = Immutable.fromJS({
    nextCursor: null,
    items: []
});
export const StarState = Immutable.fromJS({
    nextCursor: null,
    items: [],
    cats: []
});
export const AttentionState = Immutable.fromJS({});

export const TipState = Immutable.fromJS({});
export const OrderState = Immutable.fromJS({
    orderList: [],
    orderInfos: {}
});


export const SkuState = Immutable.fromJS({});

export const displayDefaultState = Immutable.fromJS({
  infos: [],
  info: null,
  status: 'init',
  nextCursor: 0,
  scrollTop: 0
})

export const BannerState = Immutable.fromJS({});

export const FeaturesState = Immutable.fromJS({});

export const QRCodeState = Immutable.fromJS({
  pages: [{
    key: 'commodityDetail',
    name: '商品详情页',
    path: 'package/pages/commodityDetail/index',
  }, {
    key: 'giaSearchResult',
    name: 'GIA搜索结果',
    path: 'package/pages/searchList/index',
  }, {
    key: 'others',
    name: '其他',
  }],
  path: 'package/pages/commodityDetail/index',
  page: ['commodityDetail'],
  query: {},
  qrcode: null,
})



