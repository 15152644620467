import {handleActions} from 'redux-actions';
import {ItemsState} from '../constants/models';
import {UPDATE_ITEMS, SET_ITEM_STAR} from '../constants/actionTypes';
import {List} from 'immutable';

const ItemsReducers = handleActions({
    UPDATE_ITEMS: (state, {payload}) => {
        return state.set('items', payload.items).set("nextCursor", payload.nextCursor);
    },
    ADD_ITEMS: (state, {payload}) => {
        return state.update('items', (items) => {
            return items.concat(payload.items);
        }).set('nextCursor', payload.nextCursor);
    },
    SET_ITEM_STAR: (state, {payload}) => {
        let itemId = payload.itemId;
        let collected = payload.collected;
        let newItems = state.get("items").map((item) => {
            if (itemId == item.id) {
                item.collected = collected;
            }
            return item;
        });
        return state.set("items", newItems);
    }
}, ItemsState);

export default ItemsReducers;