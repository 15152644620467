import {handleActions} from 'redux-actions';
import {FeaturesState} from '../constants/models';
import {SET_FEATURES} from '../constants/actionTypes';

const FeaturesReducers = handleActions({
    SET_FEATURES: (state, {payload}) => {
        return state.merge(payload);
    },
}, FeaturesState);

export default FeaturesReducers;