import {
  combineReducers,
} from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import reducers from './client/reducers';
import modules from './client/modules';

export default function (history) {
  return combineReducers({
    router: connectRouter(history),
    ...reducers,
    ...modules,
  });
}
