import {handleActions} from 'redux-actions';
import {UserState} from '../constants/models';
import {SET_USER, SET_USER_INFO, SET_THIRD_AUTH_LINK} from '../constants/actionTypes';


const UserReducers = handleActions({
    SET_USER: (state, {payload}) => {
        return state.set(payload.key, payload.value);
    },
    SET_USER_INFO: (state, {payload}) => {
        let updateInfo = {
            info: {
            }
        };
        updateInfo.info[payload.key] = payload.value;
        return state.mergeDeep(updateInfo);
    },
    REPLACE_USER_INFO: (state, {payload}) => {
        return state.merge({"info": payload.info});
    }
}, UserState);

export default UserReducers;
