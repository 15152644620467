import Loadable from 'react-loadable';
import React from 'react';

const LoadableComponent = Loadable({
  loader: () => import('../main/Pagelet'),
  loading: ({ error }) => {
    if (error) {
      return <div>Error: {error.message}</div>;
    }
    
    return null
  },
});

export default LoadableComponent;