import {handleActions} from 'redux-actions';
import {SkuState} from '../constants/models';
import {SET_SKUS} from '../constants/actionTypes';
import {fromJS} from 'immutable';


const SkuReducer = handleActions({
    SET_SKUS: (state, {payload}) => {
        return fromJS(payload)
    },
}, SkuState);

export default SkuReducer;
