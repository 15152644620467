import {
  createBrowserHistory,
  createMemoryHistory,
} from 'history';
import Immutable from 'immutable';
import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import {
  routerMiddleware,
} from 'connected-react-router/immutable';
import createRootReducer from './createRootReducer'

export default () => ({
  isServer,
  historyOptions,
  state,
}) => {
  const preloadedState = state || Immutable.Map();
  const history = isServer ? createMemoryHistory(historyOptions) : createBrowserHistory(historyOptions);
  const composeEnhancers = isServer ? compose : (process.env.EGG_SERVER_ENV === 'prod' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose));
  const middleware = [thunk, routerMiddleware(history)];
  const composedEnhancers = composeEnhancers(
    applyMiddleware(...middleware)
  );
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composedEnhancers
  );

  return {
    history,
    store,
  };
}
