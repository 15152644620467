import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import Immutable from 'immutable';
import transit from 'transit-immutable-js';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import createStore from './createStore';
import Main from './client/pagelet/main';

(function () {
	if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
		handleFontSize();
	} else {
		document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
	}
	function handleFontSize() {
		// 设置网页字体为默认大小
		WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
		// 重写设置网页字体大小的事件
		WeixinJSBridge.on('menu:setfont', function () {
			WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
		});
	}
})();

const props = window.__PAGELET_STORE__;
const {
  store,
  history,
} = createStore()({
  isServer: false,
  historyOptions: {
    basename: process.env.BASENAME,
  },
  state: props ? transit.fromJSON(JSON.stringify(props)) : Immutable.Map(),
});

Loadable.preloadReady().then(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Main />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('main_pagelet')
  );
});
