import {
  handleAction,
  createAction,
} from 'redux-actions';

const CHANGE = 'preparation/serverFetch/CHANGE';

export default handleAction(
  CHANGE,
  (state, {
    payload,
  }) => ({
    status: payload,
  }),
  {
    status: '',
  },
);

export const changeStatus = createAction(CHANGE);
