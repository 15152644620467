import {handleActions} from 'redux-actions';
import {TipState} from '../constants/models';
import {SET_TIP} from '../constants/actionTypes';

const TipReducers = handleActions({
    SET_TIP: (state, {payload}) => {
        return state.set(payload.key, payload.value);
    }
}, TipState);

export default TipReducers;