import selected from './SelectedReducer';
import user from './UserReducer';
import options from './OptionsReducer';
import items from './ItemsReducer';
import order from './orderReducer';
import tip from './tipReducer';
import cats from './catsReducer';
import skuState from './SkuReducer';
import display from './displayReducer';
import banner from './bannerReducer';
import features from './featuresReducer';
import qrcode from './qrcodeReducer';

const rootReducer = {
  user: user,
  options: options,
  items: items,
  selected: selected,
  order: order,
  tip:tip,
  cats:cats,
  skuState:skuState,
  display,
  banner:banner,
  features:features,
  qrcode,
};

export default rootReducer;
