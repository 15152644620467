import {
  handleActions
} from 'redux-actions'
import {
  displayDefaultState
} from '../constants/models'
import {
  GET_DISPLAY_INFO,
  GET_DISPLAY_INFOS,
  CLEAR_DISPLAY_INFOS,
  SAVE_DISPLAY_SCROLLTOP
} from '../constants/actionTypes'

export default handleActions({
  [GET_DISPLAY_INFOS]: (state, { payload }) => {
    const infos = state.get('infos')
    const nextCursor = state.get('nextCursor')

    return state.merge({
      infos: payload.infos ? (Number(nextCursor) === 0 ? payload.infos : [...infos, ...payload.infos]) : infos,
      nextCursor: payload.nextCursor || nextCursor,
      status: payload.status
    })
  },
  [GET_DISPLAY_INFO]: (state, { payload }) => {
    return state.merge({
      info: payload
    })
  },
  [CLEAR_DISPLAY_INFOS]: (state) => {
    return state.merge({
      infos: [],
      nextCursor: 0,
      status: 'init',
      scrollTop: 0
    })
  },
  [SAVE_DISPLAY_SCROLLTOP]: (state, { payload }) => {
    return state.merge({
      scrollTop: payload
    })
  }
}, displayDefaultState)
