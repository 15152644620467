import {handleActions} from 'redux-actions';
import {BannerState} from '../constants/models';
import {SET_BANNERS} from '../constants/actionTypes';

const bannerReducers = handleActions({
    SET_BANNERS: (state, {payload}) => {
        return state.merge(payload);
    },
}, BannerState);

export default bannerReducers;